<template>
  <div
    v-if="!isLogin"
    class="elv-financials-login-box"
    :class="isFixedMenu && isOpenState ? 'width_little' : 'width_big'"
  >
    <div class="elv-financials-login-main">
      <div class="elv-financials-login-main__dsc">{{ t('title.loginToExploreFurther') }}</div>
      <!-- form 表单 -->
      <el-form
        ref="loginFormRef"
        :label-position="'top'"
        label-width="100px"
        style="max-width: 320px"
        :model="loginForm"
        :rules="rules"
        :hide-required-asterisk="false"
      >
        <!-- 邮箱 -->
        <el-form-item v-if="loginState === 1" :label="t('common.email')" prop="email">
          <el-input
            v-model="loginForm.email"
            class="elv-login-input"
            clearable
            :placeholder="t('placeholder.enterEmail')"
          >
          </el-input>
        </el-form-item>
        <div v-if="loginState === 1">
          <!-- 验证码 -->
          <el-form-item v-if="isPasswordLogin" :label="t('common.captcha')" prop="authCode">
            <el-input
              v-model="loginForm.authCode"
              class="elv-login-input"
              :placeholder="t('placeholder.enterNumber')"
              @keyup.enter="login"
            >
              <template #suffix>
                <el-button
                  class="elv-send-button"
                  :disabled="verificationCodeOptions.disabled"
                  text
                  size="large"
                  :loading="verificationCodeOptions.isLoading"
                  @click="sendCaptcha"
                >
                  {{ verificationCodeOptions.text }}
                </el-button>
              </template>
            </el-input>
          </el-form-item>

          <!-- 密码 -->
          <el-form-item v-else :label="t('common.password')" prop="password">
            <el-input
              v-model="loginForm.password"
              class="elv-login-input"
              :placeholder="t('placeholder.enterPassword')"
              type="password"
              show-password
              @keyup.enter="login"
            />
          </el-form-item>
        </div>

        <el-form-item v-if="loginState === 2" :label="t('input.nameLabel')" prop="name">
          <el-input v-model="loginForm.name" class="elv-login-input" :placeholder="t('placeholder.enterName')" />
        </el-form-item>
        <el-form-item v-if="loginState === 2" :label="t('input.passwordsLabel')" prop="password">
          <el-input
            v-model="loginForm.password"
            class="elv-login-input"
            type="password"
            show-password
            :placeholder="t('placeholder.enterPassword')"
          />
        </el-form-item>
        <!-- 协议勾选 -->
        <el-form-item>
          <el-row>
            <el-form-item label-placement="left">
              <el-checkbox
                v-model="loginForm.checkboxValue"
                :checked="loginForm.checkboxValue"
                class="elv-login-form-checkbox-content"
              >
                {{ t('common.agreementText') }}
                <!-- <router-link to="/pact/privacyAgreement" class="elv-login-form-checkbox-content-link"> </router-link> -->
              </el-checkbox>
              <span class="elv-login-form-checkbox-content-link" @click="onJumpService()">
                {{ t('common.userService') }}
              </span>
            </el-form-item>
          </el-row>
        </el-form-item>

        <el-form-item>
          <div class="elv-login-button-box">
            <el-button
              v-if="loginState === 1"
              color="#1753EB"
              style="color: #ffffff"
              class="elv-login-button"
              :loading="loginForm.isLoading"
              @click="login"
            >
              {{ t('button.login') }}</el-button
            >
            <el-button
              v-if="loginState === 1 && isPasswordLogin"
              class="elv-login-button--plain"
              @click="changeLoginType"
            >
              {{ isPasswordLogin ? t('common.password') : t('common.captcha') }}
            </el-button>
            <el-button
              v-if="loginState === 1 && !isPasswordLogin"
              class="elv-login-button--plain"
              @click="changeLoginType"
            >
              {{ isPasswordLogin ? t('common.password') : t('common.captcha') }}
            </el-button>

            <el-button
              v-if="loginState === 2"
              color="#1753EB"
              style="color: #ffffff"
              class="elv-login-button"
              :loading="loginForm.isLoading"
              @click="onRegister"
            >
              {{ t('button.ok') }}</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import UserApi from '@/api/UserApi'
import { ElMessage } from 'element-plus'
import { useCookies } from 'vue3-cookies'
import type { FormInstance, FormRules } from 'element-plus'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'

const { t } = useI18n()
const { cookies } = useCookies()
const menuGlobalStore = useMenuGlobalStore()
const { isOpenState, isFixedMenu } = storeToRefs(menuGlobalStore)

const userGlobalStore = useUserGlobalStore()
// 根据userGlobalStore中的isLogin，判断“Login”按钮是否展示
const { isLogin } = storeToRefs(userGlobalStore)
const router = useRouter()

const isPasswordLogin = ref<boolean>(true)
const loginFormRef = ref<FormInstance>()
const loginState = ref<number>(1)
// 表单变量
interface VerificationCodeType {
  text: string
  duration: number
  disabled: boolean
  isLoading: boolean
  timer: any
}
const verificationCodeOptions = reactive<VerificationCodeType>({
  text: `${t('button.sendCaptchaText')}`,
  duration: 60,
  isLoading: false,
  disabled: false,
  timer: null
})

const loginForm = ref({
  email: '',
  authCode: '',
  password: '',
  name: '',

  checkboxValue: true,
  showIcon: true,
  isLoading: false
})

// 表单规则定义
const rules = reactive<FormRules>({
  email: [
    {
      required: true,
      message: `${t('message.pleaseInputEmail')}`,
      trigger: 'blur',
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(`${t('message.pleaseInputEmail')}.`)
        }
        // eslint-disable-next-line no-useless-escape
        if (!/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/.test(value)) {
          return new Error(`${t('message.pleaseInputEmail')}.`)
        }
        return true
      }
    }
  ],
  authCode: [
    {
      required: true,
      message: `${t('message.PleaseInputCode')}`,
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(`${t('message.captchaWarning')}`)
        }
        return true
      },
      trigger: 'blur'
    }
  ],
  name: [
    {
      required: true,
      message: `${t('message.pleaseInputName')}`,
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(`${t('message.pleaseInputName')}`)
        }
        return true
      },
      trigger: ['blur']
    }
  ],
  password: [
    {
      required: true,
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(`${t('message.pleaseInputPassword')}`)
        }
        const reg =
          /^S*(?=\S{8,})(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]/

        if ((!reg.test(value) && loginState.value === 2) || value.length < 6) {
          return new Error(`${t('message.passwordError')}`)
        }
        return true
      },
      trigger: ['blur']
    }
  ]
})

// 改变验证码按钮方法
function timer() {
  verificationCodeOptions.disabled = true
  // 倒计时方法
  // 清除掉定时器
  // eslint-disable-next-line no-unused-expressions
  verificationCodeOptions.timer && clearInterval(verificationCodeOptions.timer)
  // 开启定时器
  verificationCodeOptions.timer = setInterval(() => {
    // eslint-disable-next-line no-plusplus
    const tmp = verificationCodeOptions.duration--
    verificationCodeOptions.text = `${tmp}${` ${t('common.sendCaptchaCountdown')}`}`
    if (tmp <= 0) {
      // 清除掉定时器
      clearInterval(verificationCodeOptions.timer)
      verificationCodeOptions.duration = 60
      verificationCodeOptions.text = `${t('button.sendCaptchaText')}`
      // 设置按钮可以单击
      verificationCodeOptions.disabled = false
    }
  }, 1000)
}

// 获取验证码方法
// eslint-disable-next-line no-unused-vars
const sendCaptcha = (e: MouseEvent) => {
  // 此处只校验 是否填写邮箱
  loginFormRef.value?.validateField('email', async (isValidate) => {
    if (isValidate) {
      try {
        verificationCodeOptions.isLoading = true
        await UserApi.sendCaptcha(loginForm.value.email)
        timer()
        verificationCodeOptions.isLoading = false
      } catch (errors: any) {
        ElMessage.error(utils.formatLanguageContent(errors.message))
      }
    }
  })
}

// 登录
const login = async () => {
  loginFormRef.value?.validate(async (isValidate) => {
    if (!loginForm.value.checkboxValue) {
      return false
    }
    loginForm.value.isLoading = true
    if (isValidate) {
      try {
        const { data } = await UserApi.userLogin(
          loginForm.value.email,
          Number(loginForm.value.authCode),
          loginForm.value.password
        )
        // 登录成功
        cookies.set('elv-app-token', data.token, '7d', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN, true)
        await userGlobalStore.userInit()
        // 跳转页面
        // router.push('/')
        userGlobalStore.closeLogin()
      } catch (error: any) {
        // 未设置密码
        if (error.code === 10011) {
          loginState.value = 2
        } else {
          ElMessage.error(utils.formatLanguageContent(error.message))
        }
      }
    }
    loginForm.value.isLoading = false
    return true
  })
}
// 注册
const onRegister = async () => {
  if (!loginForm.value.checkboxValue) {
    return false
  }
  loginFormRef.value?.validate(async (isValidate) => {
    if (isValidate) {
      try {
        loginForm.value.isLoading = true
        const params = {
          email: loginForm.value.email,
          name: loginForm.value.name,
          password: loginForm.value.password,
          code: Number(loginForm.value.authCode)
        }
        const { data } = await UserApi.userRegister(params)
        // 注册成功
        cookies.set('elv-app-token', data.token, '7d', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN, true)
        await userGlobalStore.userInit()
        // 跳转页面
        // router.push('/')
        userGlobalStore.closeLogin()
      } catch (error: any) {
        loginForm.value.isLoading = false
        ElMessage.error(utils.formatLanguageContent(error.message))
      }
    }
  })
  return true
}

// 改变登录类型
const changeLoginType = () => {
  loginForm.value.authCode = ''
  loginForm.value.password = ''
  isPasswordLogin.value = !isPasswordLogin.value
}

// 跳转隐私协议
const onJumpService = () => {
  router.push('/pact/privacyAgreement')
  setTimeout(() => {
    userGlobalStore.closeLogin()
  }, 500)
}
</script>

<style scoped lang="scss">
.elv-financials-login-box {
  position: absolute;
  top: 180px;
  background: rgba(255, 255, 255, 0.9);
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  z-index: 999;
}

.width_big {
  width: calc(100vw - 47px);
}

.width_little {
  width: calc(100vw - 179px);
}

.elv-financials-login-main {
  margin-top: 100px;
  width: 390px;
  padding-left: 36px;
  padding-top: 38px;
  box-sizing: border-box;
  background: $elv-color-FFFFFF;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 2px 30px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  height: 450px;

  .elv-financials-login-main__svg {
    height: 36px;
    width: 100%;
  }

  .elv-financials-login-main__dsc {
    margin-bottom: 40px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;
    font-size: 22px;
    line-height: 14px;
    color: $elv-color-000000;
  }
}

.elv-login-form-checkbox-content {
  color: $elv-color-999BA0 !important;
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 14px;
  height: 14px;
  line-height: 14px;
}

.elv-login-form-checkbox-content-link {
  color: $elv-theme-color;
  text-decoration: none;
  border-bottom: 1px solid $elv-theme-color;
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 14px;
  height: 14px;
  line-height: 14px;
  padding-bottom: 1px;
  //   position: relative;
  //   top: 2px;
  cursor: pointer;
}

.elv-login-button-box {
  padding-bottom: 60px;
  display: flex;
  flex-wrap: wrap;

  .elv-login-button {
    width: 320px;
    height: 44px;
    margin-top: 10px;
    font-weight: 500;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    border-radius: 28px;
  }

  .elv-login-button--plain {
    width: 320px;
    height: 44px;
    margin-top: 10px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 14px;
    border-radius: 28px;
    border: none;
    margin-left: 0;

    &:hover {
      color: $elv-color-1343BF;
    }
  }
}

.elv-login-input {
  height: 44px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;
  caret-color: $elv-color-2A56E2;
}

.elv-send-button {
  // width: 36px;
  height: 14px;
  color: $elv-color-0E1420;

  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;

  &:hover {
    color: $elv-color-2D62EB;
    background-color: $elv-color-FFFFFF !important;
  }
}
</style>
<style lang="scss">
.elv-financials-login-main {
  margin-top: 100px;
  width: 390px;
  padding-left: 36px;
  padding-top: 38px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 2px 30px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  height: 450px;

  .elv-financials-login-main__svg {
    height: 36px;
    width: 100%;
  }

  .elv-financials-login-main__dsc {
    margin-bottom: 40px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;
    font-size: 22px;
    line-height: 14px;
    color: #000000;
  }

  .el-form-item__label {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: $elv-color-0E1420;

    &::before {
      // 隐藏掉必填的小红星
      display: none;
    }
  }

  .el-input__inner {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: $elv-theme-text-color;
  }
  /*修改提示字的颜色*/
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $elv-theme-tertiary-text-color;
  }

  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $elv-theme-tertiary-text-color;
  }

  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $elv-theme-tertiary-text-color;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $elv-theme-tertiary-text-color;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    --el-checkbox-checked-bg-color: #{$elv-theme-color};
    --el-checkbox-checked-input-border-color: #{$elv-theme-color};
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $elv-color-999BA0;
  }

  .el-input__wrapper:hover {
    box-shadow: 0 0 0 1px $elv-color-2A56E2 inset;
  }

  .el-input__wrapper.is-focus {
    box-shadow: 0 0 0 1px $elv-color-2A56E2 inset;
  }

  .el-form-item.is-error .el-input__wrapper {
    box-shadow: 0 0 0 1px $elv-color-E17570 inset;
  }

  .el-form-item__error {
    color: $elv-color-E17570;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    margin-top: 4px;
    position: inherit;
  }
}
</style>
